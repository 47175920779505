.ch-children {
  /* font-size: 15px; */
  position: relative;
  top: 1.5px;
  margin-left: 5px;
}

.ch-badge {
  position: relative;
  top: -1.1px;
}