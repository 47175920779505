.icon-container {
  width: 25px;
  height: 30px;

}

.lll {
  border: 1px solid red;
}

/* .icon {
  font-size: 20px;
  color: #EA2027;
} */

.icon {
  width: 20px;
  height: 32px;
}

.map-container {
  height: 100vh;
  width: 100%;
}